import React from "react"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import Layout from "../template/Layout"
import logo from '../images/logo.png'

function index() {
  return (
    <Layout>
      <div className='grid grid-rows-1'>
        <div className='logo flex justify-center'>
          <img className={'w-40 max-h-40 md:w-32 lg:w-48 md:max-h-32 lg:max-h-48'} src={logo} alt="Logo" />
        </div>
        <div className='link flex justify-center px-3 pb-5'>
          <OutboundLink onClick={e => {
            trackCustomEvent({
              category: "Button",
              action: "Click",
              label: "Website Button Clicked",
              value: 500
            })}} className={'transition-colors duration-300 ease-in-out w-full text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-400 hover:bg-gray-50 hover:text-gray-900 no-underline'} href="https://www.kodemerah.com">
            <svg className='button__icon inline-block' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
            </svg>
            Kunjungi Website Kami
          </OutboundLink>
        </div>
        <div className='link flex justify-center px-3 pb-5'>
          <OutboundLink onClick={e => {
            trackCustomEvent({
              category: "Button",
              action: "Click",
              label: "Halaman Instagram Button Clicked",
              value: 500
            })}} className={'transition-colors duration-300 ease-in-out w-full text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-400 hover:bg-gray-50 hover:text-gray-900 no-underline'} href="https://www.kodemerah.com/jasa-iklan-instagram/">
            <svg className='button__icon inline-block' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
            </svg>
            Pemesanan Instagram Ads
          </OutboundLink>
        </div>
        <div className='link flex justify-center px-3 pb-5'>
          <OutboundLink onClick={e => {
            trackCustomEvent({
              category: "Button",
              action: "Click",
              label: "Halaman Facebook Button Clicked",
              value: 500
            })}} className={'transition-colors duration-300 ease-in-out w-full text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-400 hover:bg-gray-50 hover:text-gray-900 no-underline'} href="https://www.kodemerah.com/jasa-iklan-facebook">
            <svg className='button__icon inline-block' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
            </svg>
            Pemesanan Facebook Ads
          </OutboundLink>
        </div>
        <div className='link flex justify-center px-3 pb-5'>
          <OutboundLink onClick={e => {
            trackCustomEvent({
              category: "Button",
              action: "Click",
              label: "Halaman Google Button Clicked",
              value: 500
            })}} className={'transition-colors duration-300 ease-in-out w-full text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-400 hover:bg-gray-50 hover:text-gray-900 no-underline'} href="https://www.kodemerah.com/jasa-iklan-google-ads">
            <svg className='button__icon inline-block' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
            </svg>
            Pemesanan Google Ads
          </OutboundLink>
        </div>
        <div className='link flex justify-center px-3 pb-5'>
          <OutboundLink onClick={e => {
            trackCustomEvent({
              category: "Button",
              action: "Click",
              label: "Chat Tiktok Clicked",
              value: 500
            })}} className={'transition-colors duration-300 ease-in-out w-full text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-400 hover:bg-gray-50 hover:text-gray-900 no-underline'} href="https://api.whatsapp.com/send?phone=6281280666868&text=Halo,%20saya%20tertarik%20untuk%20beriklan%20Tiktok.%20Bagaimana%20caranya%20ya?">
            <svg className='button__icon inline-block' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
            </svg>
            Pemesanan Tiktok Ads
          </OutboundLink>
        </div>
        <div className='link flex justify-center px-3 pb-5'>
          <OutboundLink onClick={e => {
            trackCustomEvent({
              category: "Button",
              action: "Click",
              label: "Chat Instagram Clicked",
              value: 500
            })}} className={'transition-colors duration-300 ease-in-out w-full text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-400 hover:bg-gray-50 hover:text-gray-900 no-underline'} href="https://api.whatsapp.com/send?phone=6281280666868&text=Halo,%20saya%20tertarik%20untuk%20beriklan%20Instagram.%20Bagaimana%20caranya%20ya?">
            <svg className='button__icon inline-block' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
            </svg>
            WA Instagram Ads
          </OutboundLink>
        </div>
        <div className='link flex justify-center px-3 pb-5'>
          <OutboundLink onClick={e => {
            trackCustomEvent({
              category: "Button",
              action: "Click",
              label: "Chat Facebook Clicked",
              value: 500
            })}} className={'transition-colors duration-300 ease-in-out w-full text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-400 hover:bg-gray-50 hover:text-gray-900 no-underline'} href="https://api.whatsapp.com/send?phone=6281280666868&text=Halo,%20saya%20tertarik%20untuk%20beriklan%20Facebook.%20Bagaimana%20caranya%20ya?">
            <svg className='button__icon inline-block' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
            </svg>
            WA Facebook Ads
          </OutboundLink>
        </div>
        <div className='link flex justify-center px-3 pb-5'>
          <OutboundLink onClick={e => {
            trackCustomEvent({
              category: "Button",
              action: "Click",
              label: "Chat Google Clicked",
              value: 500
            })}} className={'transition-colors duration-300 ease-in-out w-full text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-400 hover:bg-gray-50 hover:text-gray-900 no-underline'} href="https://api.whatsapp.com/send?phone=6281280666868&text=Halo,%20saya%20tertarik%20untuk%20beriklan%20Google.%20Bagaimana%20caranya%20ya?">
            <svg className='button__icon inline-block' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
            </svg>
            WA Google Ads
          </OutboundLink>
        </div>
        <div className='link flex justify-center px-3 pb-5'>
          <OutboundLink onClick={e => {
            trackCustomEvent({
              category: "Button",
              action: "Click",
              label: "Chat Tiktok Clicked",
              value: 500
            })}} className={'transition-colors duration-300 ease-in-out w-full text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-400 hover:bg-gray-50 hover:text-gray-900 no-underline'} href="https://api.whatsapp.com/send?phone=6281280666868&text=Halo,%20saya%20tertarik%20untuk%20beriklan%20Tiktok.%20Bagaimana%20caranya%20ya?">
            <svg className='button__icon inline-block' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
            </svg>
            WA Tiktok Ads
          </OutboundLink>
        </div>
        <div className='link flex justify-center px-3 pb-5'>
          <OutboundLink onClick={e => {
            trackCustomEvent({
              category: "Button",
              action: "Click",
              label: "Chat Jasa Pembuatan Website Clicked",
              value: 500
            })}} className={'transition-colors duration-300 ease-in-out w-full text-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-400 hover:bg-gray-50 hover:text-gray-900 no-underline'} href="https://api.whatsapp.com/send?phone=6281280666868&text=Halo,%20saya%20tertarik%20untuk%20membuat%20website.%20Apa%20saja%20yang%20dibutuhkan%20ya?">
            <svg className='button__icon inline-block' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
            </svg>
            WA Jasa Pembuatan Website
          </OutboundLink>
        </div>
        <div className='footer flex justify-center text-white mt-2 mb-7'>
          <p>Developed by <OutboundLink onClick={e => {
            trackCustomEvent({
              category: "Link",
              action: "Click",
              label: "Website Developer Clicked",
              value: 250
            })}} className='no-underline text-white' href="https://www.kodemerah.com/">KodeMerah</OutboundLink></p>
        </div>
      </div>
    </Layout>
  )
}

export default index