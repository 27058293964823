import React from "react"
import { Helmet } from "react-helmet"

export default function Layout({ children }) {
  return (
    <div className='main'>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Bio Link KodeMerah</title>
          <link rel="canonical" href="https://bio.kodemerah.com" />
          <meta name="viewport" content="width=device-width, user-scalable=no" />
        </Helmet>
      <div className='content'>
        {children}
      </div>
    </div>
  )
}